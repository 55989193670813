import React, { useState } from 'react';
import { productInBasket, useLabstoreSelectors, useLabstoreStore, useRemoveStoreProduct, useUpsertStoreProduct } from 'modules/store/store/useLabstoreStore';
import { Container, Control, Icon, Modal } from 'components';
import { StoreProduct } from 'interfaces/api';
import './store.less';
import { useProductImageSrc, useTranslateProductUnits } from 'modules/store/utils';
import messages from 'messages';
import { SelectProductAmount } from 'modules/store/components/SelectProductAmount/SelectProductAmount';
import { faBarcode, faBoxes, faScannerGun, faShoppingBasket } from '@fortawesome/pro-solid-svg-icons';
import { Translate } from 'providers';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { TogglePin } from 'modules/store/components/StoreModal/TogglePin.tsx';

export const ProductDetail: React.FC<{ product: StoreProduct }> = ({ product }) => {

  const getProductImage = useProductImageSrc();
  const translateProductUnits = useTranslateProductUnits();

  const [amount, setAmount] = useState(productInBasket(product)?.amount || 1);

  const upsertBasket = useUpsertStoreProduct();
  const removeStoreProduct = useRemoveStoreProduct();

  return (
    <Container grow shrink>

      <Container horizontal grow shrink>

        <Container grow shrink className={'store-product-detail-image'}>
          <img src={getProductImage(product, false)}/>
        </Container>

        <Container grow shrink className={'store-product-detail-text'}>

          <h3>{product.manufacturer.name}</h3>
          <h1>{product.name}</h1>

          <div className={'product-detail-icon-list'}>
            {product.pzn && (<span><Icon icon={faBarcode}/>PZN: {product.pzn}</span>)}
            {product.articleNumber && (<span><Icon icon={faScannerGun}/><Translate message={messages.admin.store.products.fields.articleNumber}/>: {product.articleNumber}</span>)}
            {<span><Icon icon={faBoxes}/><Translate message={messages.admin.store.products.fields.unitAmount}/>: {translateProductUnits(product)}</span>}
          </div>

          <p className={'text-color-muted'}>{product.introduction}</p>
          <div dangerouslySetInnerHTML={{ __html: product.description }}/>

          <Container horizontal className={'product-detail-add-basket'}>

            <SelectProductAmount
              value={amount}
              onChange={setAmount}
              product={product}
              className={'margin-right-1'}
            />

            <Control
              button={{
                type: 'primary',
              }}
              icon={faShoppingBasket}
              label={productInBasket(product) ? messages.store.updateBasket : messages.store.addBasket}
              onClick={async () => {
                await upsertBasket(product, amount);
                useLabstoreStore.setState({ selectedProduct: undefined });
              }}
            />

          </Container>

          {productInBasket(product) && (
            <Container horizontal className={'product-detail-add-basket'}>
              <Control
                icon={faTrashAlt}
                label={messages.store.removeFromBasket}
                onClick={async () => {
                  await removeStoreProduct(product);
                  useLabstoreStore.setState({ selectedProduct: undefined });
                }}
              />
            </Container>
          )}

        </Container>

      </Container>

    </Container>
  );
};

export const ProductDetailModal = () => {

  const selectedProduct = useLabstoreSelectors.selectedProduct();
  const close = () => useLabstoreStore.setState({ selectedProduct: undefined });

  return (
    <Modal
      open={!!selectedProduct}
      footer={null}
      keyboard={false}
      destroyOnClose
      onCancel={close}
      children={selectedProduct ? <ProductDetail product={selectedProduct}/> : null}
      controls={[<TogglePin product={selectedProduct}/>]}
    />
  );

};
