import React from 'react';
import { Container, Control, HelgaContainer, HelgaImageType } from 'components';
import { Translate, useFormatPrice } from 'providers';
import messages from 'messages';
import { AnalysisProperties } from 'interfaces/api';
import { groupBy, map, sumBy } from 'lodash';
import './EbmModal.less';

type Props = {
  analyses?: AnalysisProperties[];
  onClose: () => void;
};

export const EbmModal: React.FC<Props> = ({ analyses, onClose }) => {

  const labels = messages.orders.ebm;

  const formatPrice = useFormatPrice();

  return (
    <HelgaContainer
      title={labels.title}
      text={labels.description}
      image={HelgaImageType.Orders}
      buttons={[(
        <Control
          label={messages.general.close}
          onClick={onClose}
          button={{}}
        />
      )]}
    >

      <Container shrink grow className={'ebm-modal'}>

        <ul>
          {map(groupBy(analyses, a => a.ebm.kapitel + '.' + a.ebm.abschnitt), ((entries, sector) => (
            <React.Fragment key={sector}>
              <li className={'sector-header'}>
                <Translate message={labels.sector} values={{ sector }}/>
              </li>
              {entries.map(e => (
                <li className={'sector-entry'} key={e.shortName}>
                  <span className={'label'}>
                    {e.name} (GOP {e.ebm.gop}):
                  </span>
                  <span className={'value'}>
                    € {formatPrice(e.ebm.bewertung)}
                  </span>
                </li>
              ))}
              <li className={'sector-total'}>
                <span className={'label'}>
                  <Translate message={labels.totalSector} values={{ sector }}/>
                </span>
                <span className={'value'}>
                  € {formatPrice(sumBy(entries, e => parseFloat(e.ebm.bewertung)))}
                </span>
              </li>
            </React.Fragment>
          )))}

          <li className={'ebm-total'}>
            <span className={'label'}>
              <Translate message={labels.totalOrder}/>
            </span>
            <span className={'value'}>
              € {formatPrice(sumBy(analyses, e => parseFloat(e.ebm.bewertung)))}
            </span>
          </li>

        </ul>

      </Container>

    </HelgaContainer>
  );

};
