import React, { useMemo } from 'react';
import { GlobalsSendContactFormRequest, Link, LinkPlatform, LinkProduct, Platform, Product, UserType } from 'interfaces/api';
import { useAuthUser } from 'modules/auth/providers';
import { useLocation } from 'react-router';
import { filter, map } from 'lodash';
import { Translate, useApi, useConfig, useLocale, useTranslate } from 'providers';
import messages from 'messages';
import { Card, Container, Icon, ModalControl, ModalFormControl, useFileTransfer } from 'components';
import { ApiRequest } from 'containers/ApiRequest';
import { faEnvelope, faFax, faGlobe, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { useEnv } from 'providers/EnvProvider';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useAuthStore } from 'modules/auth/store';
import cx from 'classnames';

export const useLinks = () => {

  const { quickAccess, links } = useConfig();
  const platform = useEnv.platform();

  const translate = useTranslate();
  const locale = useLocale();

  const user = useAuthUser();
  const endpoint = useEnv.endpoint();
  const { lid } = useAuthStore();
  const { pathname } = useLocation();
  const { globals: { getTermsAndConditions, getPrivacyStatement, getContactInfo, sendContactForm }, pdfSettings: { downloadPdfLink } } = useApi();
  const { download } = useFileTransfer();

  const overwriteLinks = useMemo((): Link[] => {
    try {
      const parsed = JSON.parse(translate(messages.general.meta.overwriteLinks));
      let xi = 1;
      return map(parsed, (link, index) => ({
        xlinksid: xi++,
        linkhref: link,
        linkname: index,
      }));
    } catch (e) {
      return undefined;
    }
  }, [locale]);

  const entries = useMemo(() => map(overwriteLinks || filter(links?.entries || [], (link) => {

    if (platform === Platform.WEB && link.platform === LinkPlatform.App) {
      return false;
    }

    if (platform !== Platform.WEB && link.platform === LinkPlatform.Web) {
      return false;
    }

    if (link.product === LinkProduct.All && !!user) {
      return true;
    }

    if (link.product === LinkProduct.Login) {
      return !user;
    }

    if (link.product === LinkProduct.ReportQuickAccess) {
      return quickAccess.enabled && pathname === quickAccess.path;
    }

    // @ts-expect-error todo
    return (pathname + '/').indexOf('/' + Product[LinkProduct[link.product]] + '/') === 0;

  }), link => (
    <li key={link.xlinksid}>
      <a href={link.linkhref} target="_blank" rel="noreferrer">
        {link.linkname}
      </a>
    </li>
  )), [pathname, links]);

  const pdfs = useMemo(() => map(filter(links?.pdfs || [], (pdf) => {
    if (pdf.product === LinkProduct.All && !!user) {
      return true;
    }

    if (pdf.product === LinkProduct.Login) {
      return !user;
    }

    if (pdf.product === LinkProduct.ReportQuickAccess) {
      return quickAccess.enabled && pathname === quickAccess.path;
    }

    // @ts-expect-error todo
    return (pathname + '/').indexOf('/' + Product[LinkProduct[pdf.product]] + '/') === 0;
  }), pdf => (
    <li key={pdf.id}>
      <a onClick={async () => {

        const file = await download({
          request: downloadPdfLink,
          data: { id: pdf.id },
        });

        const pdfBlobUrl = URL.createObjectURL(file);

        // Open in new tab
        window.open(pdfBlobUrl, '_blank', 'noopener,noreferrer');

      }}>
        {pdf.name}
      </a>
    </li>
  )), [pathname, links]);

  const tocLink = useMemo(() => links.hasToc && links.config.showToc
    ? (
      <li key={'toc'}>
        <ModalControl
          label={messages.general.toc}
          modal={{
            title: messages.general.toc,
            footer: null,
            scrollY: true,
            children: (
              <ApiRequest
                request={() => getTermsAndConditions()}
                children={({ data }) => (
                  <Container padding>
                    <div dangerouslySetInnerHTML={{ __html: data.text }}/>
                  </Container>
                )}
              />
            ),
          }}
        />
      </li>
    )
    : undefined, [links]);

  const privacyLink = useMemo(() => links.hasPrivacy && links.config.showPrivacy
    ? (
      <li key={'privacy'}>
        <ModalControl
          label={messages.general.privacy}
          modal={{
            title: messages.general.privacy,
            footer: null,
            scrollY: true,
            children: (
              <ApiRequest
                request={() => getPrivacyStatement()}
                children={({ data }) => (
                  <Container padding>
                    <div dangerouslySetInnerHTML={{ __html: data.text }}/>
                  </Container>
                )}
              />
            ),
          }}
        />
      </li>
    )
    : undefined, [links]);

  const contactLink = useMemo(() => links.config.showContact
    ? (
      <li key={'contact'}>
        <ModalControl
          label={messages.general.contact}
          modal={{
            title: messages.general.contact,
            footer: null,
            scrollY: true,
            narrow: true,
            children: (
              <ApiRequest
                request={() => getContactInfo()}
                children={({ data }) => (
                  <Container padding>

                    {data.map((info, index) => (
                      <div key={index}>
                        <Card title={filter([info.title, info.short]).join(': ')} className={cx('info-popover', 'contact-info', { 'qr-code-card': index === 0 })}>
                          {
                            index === 0 && (
                              <div className='qr-code'>
                                <img src={`${endpoint}/api/users/profile/vcard/qr/${lid}/${UserType.LAB}`} alt='qr'/>
                              </div>
                            )
                          }
                          {filter([
                            info.address && (
                              <span>{info.address}</span>
                            ),
                            info.city && (
                              <span>{info.city}</span>
                            ),
                            info.phone && (
                              <span>
                                <Icon icon={faPhone}/>
                                {info.phone}
                              </span>
                            ),
                            info.whatsapp && (
                              <span>
                                <Icon icon={faWhatsapp}/>
                                {info.phone}
                              </span>
                            ),
                            info.fax && (
                              <span>
                                <Icon icon={faFax}/>
                                {info.fax}
                              </span>
                            ),
                            info.email && (
                              <a href={'mailto:' + info.email}>
                                <Icon icon={faEnvelope}/>
                                {info.email}
                              </a>
                            ),
                            info.www && (
                              <a href={info.www} target={'_blank'} rel="noreferrer">
                                <Icon icon={faGlobe}/>
                                {info.www}
                              </a>
                            ),
                          ])}
                        </Card>
                        {
                          index === 0 && links.config.showWhatsappQR && (
                            <Card title={messages.general.whatsappContact} className={cx('info-popover', 'contact-info', 'whatsapp-contact-card')}>
                              <img src={`${endpoint}/api/whatsapp/${lid}`} alt='qr'/>
                            </Card>
                          )
                        }
                      </div>
                    ))}
                  </Container>
                )}
              />
            ),
          }}
        />
      </li>
    )
    : undefined, [links]);

  const contactFormLink = useMemo(() => (user && links.config.showContactForm)
    ? (
      <li key={'contact'}>
        <ModalFormControl
          label={messages.general.contactForm.label}
          notifications={messages.general.contactForm.notifications}
          modal={{
            okText: messages.general.send,
            title: messages.general.contactForm.title,
            scrollY: true,
            narrow: true,
          }}
          form={{
            initialValue: {} as GlobalsSendContactFormRequest,
            request: value => sendContactForm(value),
            children: ({ Input, TextArea }) => (
              <Container padding>
                <p><Translate message={messages.general.contactForm.description}/></p>
                <Input property={'subject'} label={messages.general.contactForm.subject}/>
                <TextArea property={'message'} label={messages.general.contactForm.message}/>
              </Container>
            ),
          }}
        />
      </li>
    )
    : undefined, [links]);

  return useMemo(() => {
    return filter([tocLink, privacyLink, contactLink, contactFormLink, ...pdfs, ...entries]);
  }, [entries, tocLink, privacyLink, contactLink]);

};
