import { useEffect, useMemo } from 'react';
import { OrderRuleActionType, OrderRulesIncompatibleRequirementActionOptions, OrderRulesRemoveRequirementActionOptions } from 'interfaces/api';
import { useOrderRulesContext } from './OrderRulesProvider';
import { differenceBy } from 'lodash';
import { useSetOrders } from 'modules/orders/providers';
import { usePrevious } from 'react-use';
import messages from 'messages';
import { useWarningModal } from 'components';
import { useTranslate } from 'providers';

export const useRemoveRequirement = () => {

  const { getActionsByType } = useOrderRulesContext();
  const { setAllOrSelectedOrders } = useSetOrders();

  const translate = useTranslate();
  const warningModal = useWarningModal();

  const removeRequirementActions = useMemo(() => getActionsByType(OrderRuleActionType.RemoveRequirement), [getActionsByType]);
  const prevRemoveRequirementActions = usePrevious(removeRequirementActions);
  const newRemoveRequirementActions = differenceBy(removeRequirementActions, prevRemoveRequirementActions, ({ shortName }) => shortName);

  const removeRequirements = (actions: OrderRulesRemoveRequirementActionOptions[]) => {
    actions.forEach(({ shortName }) => {
      setAllOrSelectedOrders(order => ({ ...order, requirements: order.requirements.filter(r => r.shortName !== shortName) }));
    });
  };

  useEffect(() => removeRequirements(newRemoveRequirementActions), [newRemoveRequirementActions]);

  const incompatibleRequirementActions = useMemo(() => getActionsByType(OrderRuleActionType.IncompatibleRequirement), [getActionsByType]);
  const prevIncompatibleRequirementActions = usePrevious(incompatibleRequirementActions);
  const newIncompatibleRequirementActions = differenceBy(incompatibleRequirementActions, prevIncompatibleRequirementActions, ({ shortName }) => shortName);

  const removeIncompatible = (actions: OrderRulesIncompatibleRequirementActionOptions[]) => {
    actions.forEach(({ shortName }) => {
      let found = false;
      setAllOrSelectedOrders(order => ({
        ...order, requirements: order.requirements.filter((r) => {
          if (r.shortName === shortName) {
            found = true;
            return false;
          }
          return true;

        }),
      }));

      if (found) {
        warningModal({
          title: translate(messages.orders.wizard.basket.removedIncompatible.title),
          content: translate(messages.orders.wizard.basket.removedIncompatible.content, { shortName }),
        });
      }

    });
  };

  useEffect(() => removeIncompatible(newIncompatibleRequirementActions), [newIncompatibleRequirementActions]);
};
