import React, { useEffect } from 'react';
import { subscribe, useApi, useBiometricsStoreSelectors, useIntlStoreSelectors, useLogger, useSSE } from 'providers';
import { EventType, LogLevel } from 'interfaces/api';
import { useAsync } from 'react-use';
import { useAuthStoreSelectors } from 'modules/auth/store';
import { useAutoLogin, useRedirectStartProduct } from 'modules/auth/hooks';
import { useLocation } from 'react-router';
import { useEnv } from 'providers/EnvProvider';

export const AuthProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const { authentication: { getUserDetails }, profile: { getUserAvatar } } = useApi();

  const locale = useIntlStoreSelectors.locale();
  const setLocale = useIntlStoreSelectors.setLocale();

  const user = useAuthStoreSelectors.user();
  const setUser = useAuthStoreSelectors.setUser();

  const lid = useAuthStoreSelectors.lid();
  const setAvatar = useAuthStoreSelectors.setAvatar();

  const legacy = useAuthStoreSelectors.legacy();
  const autoLogout = useAuthStoreSelectors.autoLogout();

  const { pathname } = useLocation();

  const redirectStart = useRedirectStartProduct();

  const connect = useSSE.connect();

  const logger = useLogger('AuthProvider');

  useEffect(() => {
    const userLocale = user?.locale ?? undefined;
    if (userLocale && userLocale !== locale) {
      logger.debug('setLocale', userLocale);
      setLocale(userLocale);
    }
  }, [user?.locale]);

  useEffect(() => {
    if (user?.debugEnabled) {
      logger.debug('setLogLevel Verbose');
      logger.setLogLevel(LogLevel.Verbose);
    }
  }, [user?.debugEnabled]);

  useEffect(() => {
    if (user?.id) {
      getUserAvatar({ id: user.id }, { responseType: 'blob' }).then((result) => {
        if (result?.size > 0) {
          setAvatar(result);
        }
      });
    }
  }, [user?.id]);

  useEffect(() => {
    // reconnect SSE on every user change
    connect();
  }, [user?.id]);

  useEffect(() => subscribe(EventType.SseConnected, (notification) => {
    logger.info('EventType.SseConnected', notification);
    if (user?.id && !notification?.id) {
      window.setTimeout(autoLogout, 100);
    }
  }), [user?.id]);

  useEffect(() => {
    if (user && !legacy && (pathname.startsWith('/auth') || pathname === '/')) {
      redirectStart();
    }
  }, [user, pathname]);

  const autoLogin = useAutoLogin();
  const isNative = useEnv.isNative();

  const preloadAuth = async () => {
    if (legacy) {
      return;
    }
    if (!isNative) {
      setUser(await getUserDetails());
    }
  };

  const auth = useAsync(preloadAuth, [lid]);

  const biometricsEnabled = useBiometricsStoreSelectors.enabled();
  useEffect(() => {
    autoLogin();
  }, [biometricsEnabled]);

  if (auth.loading) {
    return null;
  }

  if (auth.error) {
    throw auth.error;
  }

  return children;

};
