import React, { ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Container, Control, FormItem, Input, Popover } from 'components';
import { faPencil } from '@fortawesome/pro-light-svg-icons';
import './ModalHeaderInputControl.less';
import { useToggle } from 'hooks';
import { filter, set, uniq } from 'lodash';
import { Translate, useTranslate } from 'providers';
import { Message } from 'interfaces';
import { useClickAway } from 'react-use';
import cx from 'classnames';

export type ModalHeaderInputControlBaseProps = {
  label: Message;
  className?: string;
  maxLength?: number;
  badge?: boolean;
  readonly?: boolean;
  prefix?: string;
  onClick?: () => void;
};

export type ModalHeaderInputControlProps<P extends boolean> = ModalHeaderInputControlBaseProps & {
  id: string;
  multi?: P;
  value: string;
  splitLabels?: P extends true ? string[] : never;
  splitValues?: P extends true ? string[] : never;
  onChange?: (value: string, splitValues?: string[]) => void;
};

export function ModalHeaderInputControl<P extends boolean>(props: ModalHeaderInputControlProps<P>): ReactNode {

  const { maxLength, prefix, badge, label, onClick, className, splitLabels, readonly, multi } = props;

  const id = 'modal-header-input-control-' + props.id;

  const translate = useTranslate();

  const [inEditMode, toggleEditMode] = useToggle(false);

  const [mainValue, setMainValue] = useState<string>(props.value);
  const [splitValues, setSplitValues] = useState<string[]>(props.splitValues || []);

  useEffect(() => {
    setMainValue(props.value);
  }, [props.value]);

  useEffect(() => {
    setSplitValues(props.splitValues);
  }, [props.splitValues]);

  // main input blur
  const onMainInputBlur = useCallback(() => {

    props.onChange(mainValue, multi ? splitValues : undefined);

    if (!multi) {
      toggleEditMode();
    }
  }, [mainValue, splitValues, props.onChange, multi]);

  // main input change trigger
  const onMainInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setMainValue(event.target.value);
    setSplitValues(splitValues?.map(v => event.target.value));
  }, [splitValues]);

  const onSplitInputChange = useCallback((splitIndex: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setSplitValues(splitValues ? set([...splitValues], splitIndex, event.target.value) : undefined);
  }, [splitValues]);

  const onSplitInputBlur = useCallback(() => {
    props.onChange(mainValue, multi ? splitValues : undefined);
  }, [props.onChange, mainValue, splitValues, multi]);

  const popoverContentRef = useRef(null);
  useClickAway(popoverContentRef, (event) => {
    if ((event.target as any).id !== id) {
      onMainInputBlur();
      toggleEditMode(false);
    }
  });

  const mainInput = (
    <Input
      id={id}
      disabled={readonly}
      autoFocus
      value={mainValue}
      onChange={onMainInputChange}
      placeholder={translate(label)}
      maxLength={maxLength}
      prefix={prefix}
      onBlur={onMainInputBlur}
      showCount
    />
  );

  const controlLabel = useMemo(() => {

    const labelValue = multi ? uniq(filter(splitValues)).join('; ') : mainValue;

    if (!labelValue && !prefix) {
      return <Translate message={label}/>;
    }

    return (
      <>
        <span className={'modal-header-input-control-meta'}>
          <Translate message={label}/>
        </span>
        <span>
          {prefix ? prefix + '; ' : ''}{labelValue}
        </span>
      </>
    );
  }, [mainValue, splitValues, prefix, multi, label]);

  const control = (
    <Control
      icon={faPencil}
      disabled={readonly}
      label={controlLabel}
      className={cx('modal-header-input-control', className)}
      alertBadge={badge}
      onClick={() => (onClick || toggleEditMode)()}
    />
  );

  if (inEditMode && !multi) {
    return mainInput;
  }

  if (multi) {
    return (
      <Popover
        placement={'bottomLeft'}
        open={inEditMode}
        content={(
          <Container className={'modal-header-input-control-popover'} ref={popoverContentRef}>
            <Container scrollY>
              <Container grow shrink>
                {splitLabels?.map((splitLabel, splitIndex) => (
                  <FormItem
                    label={splitLabel}
                    key={splitIndex}
                    floating
                  >
                    <Input
                      disabled={readonly}
                      showCount
                      value={splitValues?.[splitIndex] || ''}
                      onChange={onSplitInputChange(splitIndex)}
                      maxLength={maxLength}
                      onBlur={onSplitInputBlur}
                    />
                  </FormItem>
                ))}
              </Container>
            </Container>
          </Container>
        )}
      >
        {inEditMode ? mainInput : control}
      </Popover>
    );
  }

  return control;
}
